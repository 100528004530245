import React, { useState } from 'react'
import { useLocalStorage } from 'react-use'
import TagsContext, { Tag } from './Context'

interface Props {
  children: React.ReactNode
}

const TagsProvider = ({ children }: Props) => {
  const [savedTags, setSavedTags] = useLocalStorage<Tag[]>('tags', [])
  if (savedTags && savedTags.length && savedTags[0].weight === undefined) {
    setSavedTags([])
  }
  const [tags, setTags] = useState<Tag[]>(savedTags || [])

  const setAndSaveTags = (newTags: Tag[]) => {
    setTags(newTags)
    setSavedTags(newTags)
  }

  return (
    <TagsContext.Provider
      value={{
        tags,
        setTags: setAndSaveTags,
      }}
    >
      {children}
    </TagsContext.Provider>
  )
}

export default TagsProvider
