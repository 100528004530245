import { createContext } from 'react'

export type Tag = {
  tag: string,
  weight: number
}

export interface ContextValues {
  tags: Tag[]
}

export interface ContextType extends ContextValues {
  setTags: (tags: Tag[]) => void
}

const TagsContext = createContext<ContextType>({
  tags: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setTags: () => {},
})

export default TagsContext
