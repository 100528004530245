import React, { useContext } from 'react';
import './App.css';
import { AppBar, Box, Container, Toolbar, Typography } from '@mui/material';
import {
  Link as RouterLink,
} from 'react-router-dom'
import Button from '@mui/material/Button';
import TagsContext from './Tags/Context';

function App() {
  const tagsContext = useContext(TagsContext);
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Projet Pro Academie
          </Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" style={{
          padding: 0, 
          flex: 1, 
          display: 'flex', 
          alignContent: 'center', 
          justifyContent: 'center', 
          alignItems: 'center'}}>
        <Box sx={{ my: 4, textAlign: 'center' }}>
          <Typography variant="h5" style={{textAlign: "center"}} component="h1" gutterBottom>
            Bienvenue, répondez aux questions et découvrez quel projet pro vous correspond. 
          </Typography>
          <Button 
            component={RouterLink}
            variant="contained"
            style={{marginTop: 20}} 
            to={'/questions'}>
              C'est parti
            </Button>
            <br/>
            {tagsContext.tags && tagsContext.tags.length > 0 && 
              <Button 
              component={RouterLink}
              variant="contained"
              style={{marginTop: 20}} 
              to={'/propositions'}>
                Revoir les propositions
              </Button>
            }
        </Box>
      </Container>
    </>
  );
}

export default App;
