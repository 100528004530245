import React, { useContext } from 'react';
import { collection, getDocs, query, where, orderBy, limit, doc, getDoc, startAfter } from "firebase/firestore"; 
import { logEvent } from "firebase/analytics";
import { analytics, db } from './DB';
import TagsContext from './Tags/Context';
import { useNavigate } from 'react-router';
import { AppBar, Box, CircularProgress, Container, Rating, Toolbar } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {
  Link as RouterLink,
} from 'react-router-dom'
import { useMount } from 'react-use';

const propositionsRef = collection(db, "propositions");
const paramRef = doc(db, "params", "main");

const Propositions = () => {
  const tagsContext = useContext(TagsContext);
  const [loading, setLoading] = React.useState(true);
  const [init, setInit] = React.useState(false);
  const navigate = useNavigate();
  const [propositions, setPropositions] = React.useState<any[]>([]);
  const [selectedPropositions, setSelectedPropositions] = React.useState<any[]>([]);
  const [activeStep, setActiveStep] = React.useState<number>(0)
  const [rate, setRate] = React.useState<number>(0);

  useMount(async () => {
    if (tagsContext.tags.length === 0) {
      navigate('/questions') 
    }
    if (propositions.length === 0 && tagsContext.tags.length > 0 && loading === true && init === false) {
      const params = await getDoc(paramRef)
      const { proposition_length } = params.data() || {proposition_length: 12};
      const topResults: any[] = [];
      const getPropositions = async (start: any) => {
        const q = query(
          propositionsRef,
          where("tags", "array-contains-any", tagsContext.tags.map((t) => t.tag).slice(0, 30)),
          orderBy("tags"),
          startAfter(start),
          limit(20)
        );
        
        const querySnapshot = await getDocs(q);
        querySnapshot.docs.map((doc) => {
          const data = doc.data();
          if (data.exclusion.filter((e: string) => tagsContext.tags.map((t) => t.tag).indexOf(e) > -1).length > 0) {
            return data;
          }
          let score = 0
          data.tags.map((tag: string) => {
            if (tagsContext.tags.filter((t) => t.tag === tag).length > 0) {
              score += tagsContext.tags.filter((t) => t.tag === tag)[0].weight;
            }
            return tag;
          })
          
          if (topResults.length < proposition_length || (topResults[topResults.length - 1].score < score)) {
            topResults.push({
              doc: doc.data(),
              score: score
            })
          }
          topResults.sort((a, b) => b.score - a.score).slice(0, proposition_length);
          return { doc: doc.data(), score: score };
        });
        if (querySnapshot.docs.length < 20) {
          setPropositions(topResults.slice(0, proposition_length || 12).map((result) => result.doc));
          setLoading(false)
        } else {
          const lastVisible = querySnapshot.docs[querySnapshot.docs.length-1];
          getPropositions(lastVisible)
        }
      }
      setInit(true)
      getPropositions(-1)
    }
  });

  const correctUrl = (url: string) => {
    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
        return `https://${url}`;
    } else { return url; }
  }

  const log = (label: string) => {
    logEvent(analytics, 'proposition_event', {
      position: activeStep + 1,
      label: label,
    });
  }

  return (
    <Box sx={{ flexGrow: 1, height: '100%', display: 'flex', flexDirection: 'column' }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
            { activeStep < propositions.length && `Proposition ${activeStep + 1} / ${propositions.length}`}
            { (activeStep >= propositions.length && !loading) && 'Félicitations!'}
            { loading && 'Chargement des propositions...'}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container 
        maxWidth="sm" 
        style={{flex: 1, paddingTop:10, display: 'flex', alignItems: activeStep < propositions.length ? 'center' : 'start', justifyContent: 'center'}}> 
        { loading && <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}><CircularProgress /></Box> }
        {propositions.length > 0 && activeStep < propositions.length && 
          <Card sx={{ maxWidth: 500 }}>
            <CardMedia
              sx={{ height: 140 }}
              image={propositions[activeStep].image_url || "https://picsum.photos/640/360"}
              title={propositions[activeStep].name}
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {propositions[activeStep].name}
              </Typography>
              <ul>
                {
                  propositions[activeStep].description.split(',').map((p: string, i: number) => {
                    return <li key={i}><Typography variant="body2" color="text.secondary">{p}</Typography></li>
                  })
                }
              </ul>
            </CardContent>
            <CardActions style={{alignItems: 'initial'}}>
              <Button 
                size="small" 
                variant="contained"
                target="_blank"
                onClick={() => log("show_more")}
                href={correctUrl(propositions[activeStep].url)}
                style={{textAlign: 'center', marginRight: '10px', flex: 1, fontSize: '14px'}}
                color="success">
                  Je veux en savoir plus
              </Button>
              <Button 
                size="small" 
                variant="contained"
                onClick={() => {
                  log("not_interested")
                  setActiveStep(prevActiveStep => prevActiveStep + 1)
                }}
                style={{flex: 1, fontSize: '14px'}}
                color="error">
                  Ça ne m'intéresse pas
              </Button>
              <Button 
                size="small"
                style={{flex: 1, fontSize: '14px'}}
                onClick={() => {
                  log("next_step")
                  setActiveStep(prevActiveStep => prevActiveStep + 1)
                  setSelectedPropositions([...selectedPropositions, propositions[activeStep]]);
                  if (activeStep === propositions.length - 1) {
                    logEvent(analytics, 'proposition_end', {
                      selected: selectedPropositions.length,
                      total: propositions.length,
                    });
                  }
                }}
                variant="contained"
                color="info">
                Proposition suivante
              </Button>
            </CardActions>
          </Card>
        }
        {propositions.length > 0 && activeStep >= propositions.length &&
          <Box sx={{ display: 'flex', paddingTop: 5, flexDirection: 'column' }}>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
              Aidez-nous à améliorer notre plateforme.
            </Typography>
            {/* <Typography variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'center' }}>
              Que faire ensuite ?
            </Typography> */}
            <Box
              sx={{
                paddingTop: 8,
                '& > legend': { mt: 2 },
              }}
            >
              <Typography component="legend">Quel note donneriez-vous à la qualité des propositions ?</Typography>
              <Rating
                name="rate"
                value={rate}
                onChange={(event, newValue) => {
                  if (newValue !== null) {
                    setRate(newValue);
                    logEvent(analytics, 'rate', {
                      propositions: newValue,
                      value: newValue
                    });
                  }
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', paddingTop: 10, flexDirection: 'column' }}>

              <Button 
                size="large"
                sx={{margin: 2}}
                variant="contained"
                href="https://www.projetproacademie.fr/nous-contacter"
                target="_blank" 
                color="success">
                  Echanger gratuitement avec un expert en projet professionnel
              </Button>
              { selectedPropositions.length > 0 ?
                <Button 
                  sx={{margin: 2}}
                  size="large" 
                  variant="contained"
                  onClick={() => {
                    setPropositions(JSON.parse(JSON.stringify(selectedPropositions)))
                    setSelectedPropositions([])
                    setActiveStep(0)
                  }}  
                  color="info">
                    Revoir les projets qui me plaisent
                </Button> : 
                <Button 
                  size="large" 
                  variant="contained"
                  sx={{margin: 2}}
                  onClick={() => {
                    setActiveStep(0)
                  }}
                  color="info">
                    Refaire le questionnaire
                </Button>
              }
              <Button 
                size="large" 
                sx={{margin: 2}}
                variant="contained"
                component={RouterLink}
                style={{marginTop: 20}} 
                to={'/questions'}
                color="warning">
                  Refaire le questionnaire
              </Button>

            </Box>
          </Box>
        }
      </Container>
    </Box>
  )
}

export default Propositions;