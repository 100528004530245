import React, { useContext } from 'react';
import { collection, getDocs } from "firebase/firestore"; 
import { db } from './DB';
import { Box, Checkbox, CircularProgress, Container, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Radio } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import TagsContext from './Tags/Context';
import { useNavigate } from'react-router-dom';
import { useMount } from 'react-use';


const questionsRef = collection(db, "questions");

type Option = {
  option: string
  tag: string,
  weight: number
}

type Question =  {
  type: string;
  question: string;
  options: Option[];
  selectedOptions: Option[];
}

const Questions = () => { 
  const [questions, setQuestions] = React.useState<Question[]>([]);
  const [loading, setLoading] = React.useState(true);
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = questions.length;
  const tagsContext = useContext(TagsContext);
  const navigate = useNavigate();

  const getResults = () => {
    const tags: any[] = []
    questions.map(q => q.selectedOptions.map(o => {
      if (tags.map((t) => t.tag).indexOf(o.tag) === -1) {
        tags.push({tag: o.tag, weight: o.weight})
      } else {
        tags.map((t) => {
          if (t.tag === o.tag) {
            t.weight += o.weight
          }
          return t;
        })
      }
      return tags
    }));
    tagsContext.setTags(tags.filter((n, i) => tags.indexOf(n) === i));
    navigate('/propositions');
  }

  const handleNext = () => {
    if (activeStep === maxSteps - 1) {
      getResults();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useMount(async () => {
    if (!questions.length) {
      const querySnapshot = await getDocs(questionsRef);
      const questionsToSet: Question[] = [];
      querySnapshot.forEach((doc) => {
        const question = doc.data() as Question;
        question.selectedOptions = [];
        questionsToSet.push(question);
      });
      setQuestions(questionsToSet);
      setLoading(false);
    }
  })

  const handleToggle = (option: Option) => {
    const selected = questions[activeStep].selectedOptions.map((s) => s.option).indexOf(option.option) > -1;
    const type = questions[activeStep].type.trim();
    if (questions[activeStep].selectedOptions.length === 4 && !selected) {
      return;
    }
    if (selected) {
      questions[activeStep].selectedOptions = questions[activeStep].selectedOptions.filter((s) => s.option!== option.option);
    } else {
      if (type === 'simple') {
        questions[activeStep].selectedOptions = [option];
      } else {
        questions[activeStep].selectedOptions.push(option);
      }
    }
    setQuestions([...questions]);
  }
  
  return (
    <Container maxWidth="sm" style={{height: '100%', background: 'white', overflow: 'auto'}}> 
      { loading && <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: 20 }}><CircularProgress /></Box> }
      { questions.length > 0 && 
        <Box sx={{ maxWidth: 600, flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Paper
            square
            elevation={0}
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: 2,
              bgcolor: 'background.default',
            }}
          >
            <Typography variant="h5" style={{textAlign: "center", paddingTop: 20}} component="h1">{questions[activeStep].question}</Typography>
          </Paper>
          <Box sx={{ flex: 1, maxWidth: 400, width: '100%', pt: 2 }}>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
              {questions[activeStep].options.map((option) => {
                const labelId = `checkbox-list-label-${option}`;

                return (
                  <ListItem
                    key={option.option}
                    disablePadding
                  >
                    <ListItemButton role={undefined} onClick={() => handleToggle(option)} dense>
                      <ListItemIcon>
                        { questions[activeStep].type.trim() === 'simple' ?
                          <Radio
                            edge="start"
                            checked={questions[activeStep].selectedOptions.map((s) => s.option).indexOf(option.option) > -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          /> :
                          <Checkbox
                            edge="start"
                            checked={questions[activeStep].selectedOptions.map((s) => s.option).indexOf(option.option) > -1}
                            disabled={questions[activeStep].selectedOptions.length === 4}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ 'aria-labelledby': labelId }}
                          /> 
                        }
                        
                      </ListItemIcon>
                      <ListItemText id={labelId} primary={option.option} />
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            { questions[activeStep].selectedOptions.length === 4 && 
              <i>Nombre de choix limité à 4</i>
            }
          </Box>
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={questions[activeStep].selectedOptions.length === 0}
              >
                {
                  activeStep === maxSteps - 1 ? 
                  'Terminer' :
                  'Suivant'
                }

                {theme.direction === 'rtl' ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                {theme.direction === 'rtl' ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Retour
              </Button>
            }
          />
        </Box>
      }
    </Container>
  )
}

export default Questions;