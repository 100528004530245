import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

//...
// On retrouve notre variable config ;)
// Remplacez par la votre si vous voulez
const firebaseConfig = {
  apiKey: "AIzaSyAMsPwlnt74zPPQgkcGAapUpRw7F60sR8M",
  authDomain: "projet-pro-academie.firebaseapp.com",
  projectId: "projet-pro-academie",
  storageBucket: "projet-pro-academie.appspot.com",
  messagingSenderId: "186516774177",
  appId: "1:186516774177:web:3d0a6e239660caa6aef260",
  measurementId: "G-6HNQ2FHD49"
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getFirestore(app);
