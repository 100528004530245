import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import Questions from './Questions';
import TagsProvider from './Tags/Provider';
import Propositions from './Propositions';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/material';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/questions",
    element: <Questions />
  },
  {
    path: "/propositions",
    element: <Propositions />
  },
]);

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgb(77, 39, 191)',
    }
  },
  typography: {
    fontFamily: [
      'Nimbus Sans',
      '-apple-system',
      'Roboto',
    ].join(','),
    fontSize: 17, 
  },
});
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <TagsProvider>
      <ThemeProvider theme={theme}>
        <div style={{
              width: '100%',
              position: 'absolute',
              zIndex: 0,
              filter: 'blur(8px)',
              height: '100%',
              overflow: 'hidden'
            }}>
          <img
            alt="Projet Pro Academie"
            src="https://firebasestorage.googleapis.com/v0/b/projet-pro-academie.appspot.com/o/Background_image?alt=media"
            style={{
              width: '100%',
            }}
          />
        </div>
        <Container style={{
          height: '100%',
          zIndex: 999,
          position: 'relative',
          width: '100%',
          maxWidth: '100%',
          padding: 0,
          display: 'flex',
          flexDirection: 'column'
        }}>
          <RouterProvider router={router} />
        </Container>
      </ThemeProvider>
    </TagsProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
